/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ProfilePicture } from "./ProfilePicture";
import {
  ProfilePictureFromJSON,
  ProfilePictureFromJSONTyped,
  ProfilePictureToJSON,
} from "./ProfilePicture";

/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  last_name?: string;
  /**
   *
   * @type {ProfilePicture}
   * @memberof Profile
   */
  picture?: ProfilePicture;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  email?: string;
}

/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProfileFromJSON(json: any): Profile {
  return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Profile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    first_name: !exists(json, "first_name") ? undefined : json["first_name"],
    last_name: !exists(json, "last_name") ? undefined : json["last_name"],
    picture: !exists(json, "picture")
      ? undefined
      : ProfilePictureFromJSON(json["picture"]),
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function ProfileToJSON(value?: Profile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.first_name,
    last_name: value.last_name,
    picture: ProfilePictureToJSON(value.picture),
    email: value.email,
  };
}
