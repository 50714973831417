/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  variant: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sku: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  src: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  inventory_quantity: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  retail_price: number;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "url" in value;
  isInstance = isInstance && "variant" in value;
  isInstance = isInstance && "sku" in value;
  isInstance = isInstance && "src" in value;
  isInstance = isInstance && "inventory_quantity" in value;
  isInstance = isInstance && "retail_price" in value;

  return isInstance;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json["title"],
    url: json["url"],
    variant: json["variant"],
    sku: json["sku"],
    src: json["src"],
    inventory_quantity: json["inventory_quantity"],
    retail_price: json["retail_price"],
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    url: value.url,
    variant: value.variant,
    sku: value.sku,
    src: value.src,
    inventory_quantity: value.inventory_quantity,
    retail_price: value.retail_price,
  };
}
