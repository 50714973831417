/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SignupSchema
 */
export interface SignupSchema {
  /**
   *
   * @type {string}
   * @memberof SignupSchema
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof SignupSchema
   */
  remember_me?: boolean;
  /**
   *
   * @type {string}
   * @memberof SignupSchema
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof SignupSchema
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof SignupSchema
   */
  last_name: string;
}

/**
 * Check if a given object implements the SignupSchema interface.
 */
export function instanceOfSignupSchema(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "first_name" in value;
  isInstance = isInstance && "last_name" in value;

  return isInstance;
}

export function SignupSchemaFromJSON(json: any): SignupSchema {
  return SignupSchemaFromJSONTyped(json, false);
}

export function SignupSchemaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SignupSchema {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    remember_me: !exists(json, "remember_me") ? undefined : json["remember_me"],
    next: !exists(json, "next") ? undefined : json["next"],
    first_name: json["first_name"],
    last_name: json["last_name"],
  };
}

export function SignupSchemaToJSON(value?: SignupSchema | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    remember_me: value.remember_me,
    next: value.next,
    first_name: value.first_name,
    last_name: value.last_name,
  };
}
